import LogoPublifarm from "../img/logo-publifarm.svg";
import LogoSaradimariaMotto from "../img/logo-saradimaria-motto.svg";
import IconArrowBrown from "../img/icon-arrow-brown.svg";
import IconArrowBrownLight from "../img/icon-arrow-brown-light.svg";
import IconMapPin from "../img/icon-map-pin.svg";
import BgElement1 from "../img/bg-element-1.svg";
import BgElement2 from "../img/bg-element-2.png";
import IconAccordionOpen from "../img/icon-accordion-open.png";
import IconAccordionClose from "../img/icon-accordion-close.png";

/**
 * Export all the theme images
 */

const themeMedia = () => {
  newImage(LogoPublifarm);
  newImage(LogoSaradimariaMotto);
  newImage(IconArrowBrown);
  newImage(IconArrowBrownLight);
  newImage(IconMapPin);
  newImage(BgElement1);
  newImage(BgElement2);
  newImage(IconAccordionOpen);
  newImage(IconAccordionClose);
};

/**
 * Compile image from src folder
 *
 * @param Image source
 */

const newImage = (source) => {
  const newImage = new Image();
  newImage.src = source;
};

export default themeMedia;
