
/**
 * Init Footer Reel Carousel
 */

const footerReel = () => {
  $(() => {
    $(".footer-reel__list").each(function() {
      const $sliderList = $(this);
      $sliderList.slick({
        dots: false,
        arrows: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 6000,
        speed: 1200,
        pauseOnFocus: true,
        draggable: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        rows: 0,
      });
    });
  });
};

export default footerReel;
