import { scrollToElement } from "../functions";

const headerNavigator = () => {
  // Resize navbar at scroll
  const navbars = document.querySelectorAll(".navbar");
  if (!navbars.length) return;

  let isScrolling = false;
  window.addEventListener("scroll", () => {
    if (isScrolling) return;

    window.requestAnimationFrame(() => {
      const scrolled = window.scrollY > 90;
      navbars.forEach((navbar) => {
        navbar.classList.toggle("scrolled", scrolled);
        isScrolling = false;
      });
    });

    isScrolling = true;
  });

  // Select the dropdown toggle element
  let dropdowns = document.querySelectorAll(".dropdown");
  if (dropdowns.length) {
    dropdowns.forEach((dropdown) => {
      let dropdownMenu = dropdown.querySelector(".dropdown-menu");

      if (dropdown && dropdownMenu) {
        // Add event listener for mouse enter
        dropdown.addEventListener("mouseenter", () => {
          openDropdown(dropdown, dropdownMenu);
        });

        dropdownMenu.addEventListener("mouseenter", () => {
          openDropdown(dropdown, dropdownMenu);
        })

        // Add event listener for mouse leave
        dropdown.addEventListener("mouseleave", () => {
          setTimeout(() => {
            closeDropdown(dropdown, dropdownMenu);
          }, 400);
        });

        dropdownMenu.addEventListener("mouseleave", () => {
          setTimeout(() => {
            closeDropdown(dropdown, dropdownMenu);
          }, 400);
        });
      }
    });
  }

  // // Fade in / out dropdown menu
  // const dropdowns = document.querySelectorAll(".dropdown");
  // if (dropdowns.length) {
  //   dropdowns.forEach((dropdown) => {
  //     const dropdownMenu = dropdown.querySelector(".dropdown-menu");

  //     dropdown.addEventListener("show.bs.dropdown", () => {
  //       fadeIn(dropdownMenu, 200);
  //     });

  //     dropdown.addEventListener("hide.bs.dropdown", () => {
  //       fadeOut(dropdownMenu, 200);
  //     });
  //   });
  // }

  // Contact Button scroll to form
  const contactBtn = document.querySelector(".navigator-lang__button__contact");
  if (contactBtn) {
    contactBtn.addEventListener("click", () => {
      const id = contactBtn.getAttribute("data-target");
      scrollToElement(id, 50);
    });
  }

  // // Manage Dropdown in desktop - Pizzi fix
  // $(() => {
  //   let header_height_init = $(".navbar__desktop").innerHeight();
  //   let header_height = 0;

  //   // Init Size
  //   resizeDropdown(header_height, header_height_init);

  //   // Resize at Scroll
  //   window.addEventListener("scroll", () => {
  //     resizeDropdown(header_height, header_height_init);
  //   });

  //   // Resize at Click
  //   $(".dropdown").on("click", () => {
  //     resizeDropdown(header_height, header_height_init);
  //   });
  // });
};

export default headerNavigator;

/**
 * Open dropdown menu
 *
 * @param {*} toggle
 * @param {*} submenu
 */

const openDropdown = (toggle, submenu) => {
  toggle.classList.add("show");
  toggle.setAttribute("aria-expanded", "true");
  submenu.classList.add("show");
  submenu.setAttribute("data-bs-popper", "static");
};

/**
 * Close dropdown menu
 *
 * @param {*} toggle
 * @param {*} submenu
 */

const closeDropdown = (toggle, submenu) => {
  toggle.classList.remove("show");
  toggle.setAttribute("aria-expanded", "false");
  submenu.classList.remove("show");
  submenu.removeAttribute("data-bs-popper");
};

// /**
//  * Dropdown Desktop - Define position top
//  *
//  * @param {*} header_height
//  * @param {*} header_height_init
//  */
// const resizeDropdown = (header_height, header_height_init) => {
//   if (window.innerWidth > 768) {
//     var scroll = $(window).scrollTop();
//     header_height = $(".navbar__desktop").innerHeight();
//     if ($(".dropdown-menu").hasClass("show")) {
//       if (scroll > 0) {
//         $(".dropdown-menu").css("top", header_height);
//       } else {
//         $(".dropdown-menu").css("top", header_height_init);
//       }
//     }
//   }
// };

// /**
//  * Fade in Dropdown Menu
//  *
//  * @param {*} element
//  * @param {*} duration
//  */
// const fadeIn = (element, duration) => {
//   element.style.opacity = 0;
//   element.style.display = "flex";

//   if (window.innerWidth < 768) {
//     element.style.flexDirection = "column";
//     element.style.border = "none";
//   }

//   var data = {
//     element: element,
//     duration: duration,
//     startTime: null,
//     opacityStart: 0,
//   };

//   requestAnimationFrame((currentTime) => animation(currentTime, data));
// };

// /**
//  * Fade out Dropdown Menu
//  *
//  * @param {*} element
//  * @param {*} duration
//  */
// function fadeOut(element, duration) {
//   var data = {
//     element: element,
//     duration: duration,
//     startTime: null,
//     opacityStart: 1,
//   };

//   requestAnimationFrame((currentTime) => animation(currentTime, data));
// }

// /**
//  * Dropdown animation
//  *
//  * @param {*} currentTime
//  * @param {*} data
//  */
// const animation = (currentTime, data) => {
//   if (!data.startTime) {
//     data.startTime = currentTime;
//   }

//   var progress = currentTime - data.startTime;
//   var math = Math.min(progress / data.duration, 1);
//   var opacity = data.opacityStart === 0 ? math : data.opacityStart - math;

//   data.element.style.opacity = opacity;

//   if (progress < data.duration) {
//     requestAnimationFrame((currentTime) => animation(currentTime, data));
//   } else if (data.opacityStart != 0) {
//     data.element.style.display = "none";
//   }
// };
