const initMap = async () => {
  const mapElement = document.getElementById("map");
  if (mapElement) {
    // Import Google Libraries
    const { Map, InfoWindow } = await google.maps.importLibrary("maps");
    const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary("marker");
    const geocoder = new google.maps.Geocoder();

    const centerPosition = {
      lat: parseFloat(mapElement.getAttribute("data-lat")),
      lng: parseFloat(mapElement.getAttribute("data-lng")),
    };

    // Create Map
    const map = new Map(mapElement, {
      center: centerPosition,
      zoom: 15,
      mapTypeControl: false,
      mapId: '3eed00db84961c49',
      zoom: 16,
    });

    const infoWindow = new InfoWindow();

    // Create Center Pin
    const CenterPin = new AdvancedMarkerElement({
      position: centerPosition,
      map,
      title: "Sara Di Maria",
    });

    // Other Pins
    const pinsPositions = [
      // { position: { lat: 37.419, lng: -122.02 }, title: "Pin Title 1" },
    ];

    pinsPositions.forEach(async ({ position, title }, i) => {
      const pin = new PinElement({
        glyph: `${i + 1}`,
        scale: 1,
      });

      const marker = new AdvancedMarkerElement({
        position,
        map,
        title: `${i + 1}. ${title}`,
        content: pin.element,
        gmpClickable: true,
      });

      const address = await getAddress(position, geocoder);

      marker.addListener("click", ({ domEvent, latLng }) => {
        const { target } = domEvent;

        infoWindow.close();
        infoWindow.setContent(`
                 <div class="info-window">
                   <h3>${marker.title}</strong></h3>
                   <p>${address}</p>
                   <footer>
                     <a href="https://www.google.com/maps/search/?api=1&query=${marker.position.lat},${marker.position.lng}" target="_blank">
                       Visualizza su Google Maps
                     </a>
                   </footer>
                 </div>
               `);
        infoWindow.open(marker.map, marker);
      });
    });
  }
};

export default initMap;

/**
 * Function to reverse geocode and get address
 *
 * @param {*} latLng
 * @param {*} geocoder
 * @returns
 */

const getAddress = async (latLng, geocoder) => {
  return new Promise((resolve, reject) => {
    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          resolve(results[0].formatted_address);
        } else {
          resolve("No address found");
        }
      } else {
        reject("Geocoder failed due to: " + status);
      }
    });
  });
};
