const templateServiziEsclusivi = () => {
  const hash = window.location.hash,
    offset = window.innerWidth > 768 ? 160 : 90;
  if (hash) {
    window.onload = () => {
      const element = document.querySelector(hash);
      if (element) {
        let body = element.querySelector(
            ".exclusive-accordion__accordion__body"
          ),
          btn = element.querySelector(
            ".exclusive-accordion__accordion__button"
          );
        body.classList.add("show");
        btn.classList.remove("collapsed");
        btn.setAttribute("aria-expanded", "true");
        setTimeout(() => {
          window.scrollTo({
            top: element.getBoundingClientRect().top + window.scrollY - offset,
            behavior: "smooth",
          });
        }, 200);
      }
    };
  }
};

export default templateServiziEsclusivi;
