import { handleWindowSize } from "../functions";

/**
 * Init Block Grid Link Carousel
 */

const sectionGridLink = () => {
  $(() => {
    const breakpoint = 992,
      selector = ".grid-link__list",
      config = {
        dots: true,
        arrows: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 6000,
        speed: 1200,
        draggable: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        rows: 0,
        responsive: [
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      };

    if ($(selector).length) {
      handleWindowSize(breakpoint, selector, config);
      window.addEventListener("resize", () => {
        handleWindowSize(breakpoint, selector, config);
      });
    }
  });
};

export default sectionGridLink;
