
/**
 * Init Block Card Gallery
 */

const sectionSlider = () => {
  $(() => {
    $(".card-image__list").each(function() {
      const $sliderList = $(this);
      $sliderList.slick({
        dots: false,
        arrows: false,
        infinite: true,
        autoplay: true,
        speed: 700,
        draggable: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        rows: 0,
        fade: true,
        cssEase: 'ease-in-out',
      });
    });
  });
};

export default sectionSlider;
