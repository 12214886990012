const templateEventi = () => {
    $(() => {
        $(".event__media__wrapper").each(function() {
          const $sliderList = $(this);
          $sliderList.slick({
            dots: false,
            arrows: false,
            infinite: true,
            autoplay: true,
            speed: 300,
            draggable: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            rows: 0,
            fade: true,
            cssEase: 'ease-in-out',
          });
        });
      });
};

export default templateEventi;
