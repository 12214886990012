
/**
 * Init Slider
 *
 * @param string selector
 * @param {*} config
 */

export const initSlider = (selector, config) => {
  $(selector).each(function () {
    const $slider = $(this);
    if (!$slider.hasClass("slick-initialized")) {
      $slider.slick(config);
    }
  });
};

/**
 * Unset Slider
 *
 * @param string selector
 */

export const unsetSlider = (selector) => {
  $(selector).each(function () {
    const $slider = $(this);
    if ($slider.hasClass("slick-initialized")) {
      $slider.slick("unslick");
    }
  });
};

/**
 * Handle Window Size
 *
 * @param int breakpoint
 * @param string selector
 * @param {*} config
 */

export const handleWindowSize = (breakpoint, selector, config) => {
  if (window.innerWidth > breakpoint) {
    unsetSlider(selector);
  } else {
    initSlider(selector, config);
  }
};

/**
 * Animation to manage scroll to element
 *
 * @param {*} id
 * @param {*} topFix
 */

export const scrollToElement = (id, topFix = 0) => {
  const element = document.getElementById(id);
  if (element) {
    let offset = window.innerWidth > 768 ? 160 : 90;
    offset = offset + topFix;
    window.scrollTo({
      top: element.getBoundingClientRect().top + window.scrollY - offset,
      behavior: "smooth",
    });
    console.log('offset');
  }
};
