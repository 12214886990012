import { handleWindowSize } from "../functions";

/**
 * Init Block Card Text Carousel
 */

const sectionCardText = () => {
  $(() => {
    // Slider
    const breakpoint = 992,
      selector = ".card-text__list",
      config = {
        dots: false,
        arrows: false,
        infinite: true,
        autoplay: true,
        speed: 700,
        draggable: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        rows: 0,
        responsive: [
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 3,
            },
          },
        ],
      };

    if ($(selector).length) {
      handleWindowSize(breakpoint, selector, config);
      window.addEventListener("resize", () => {
        handleWindowSize(breakpoint, selector, config);
      });
    }
    
  });
};

export default sectionCardText;
