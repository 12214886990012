const footerForm = () => {
  const privacyCol = document.querySelector(".footer-form__col-privacy");
  if (privacyCol) {
    const privacyCheck = privacyCol.querySelector('input[type="checkbox"]');
    if (privacyCheck) {
      privacyCheck.addEventListener("click", () => {
        if (privacyCheck.checked) {
          privacyCol.classList.add("checked");
        } else {
          privacyCol.classList.remove("checked");
        }
      });
    }
  }
};

export default footerForm;
