const sectionCardMap = () => {
  $(() => {

    $(".card-map__button").on("click", function () {
      let city_img = $(this).attr("data-image");
      $(".place_preview").fadeOut("fast", function() {
        $(this).css("background-image", 'url("' + city_img + '")');
        $(this).fadeIn("fast");
      });
    });
  });
};

export default sectionCardMap;
