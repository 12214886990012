
const footerBanner = () => {
  $(() => {
    $(".footer-banner__list").each(function () {
      const $sliderList = $(this);
      $sliderList.slick({
        dots: false,
        arrows: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 0,
        speed: 9000,
        draggable: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: true,
        centerMode: true,
        variableWidth: true,
        pauseOnHover: true,
        cssEase: "linear",
        rows: 0,
      });
    });
  });
};

export default footerBanner;
